import { gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { trackAction } from "../../services/firebaseAnalytics";
import { Mutation, MutationUserInputRfqArgs } from "../../typing/gql.public.schema";

export const MUTATION_SUBMIT_RFQ = gql`
  mutation userInputRfq(
    $deliveryAddress: String!
    $deliveryDate: String!
    $phased: Boolean!
    $status: Int!
    $cartName: String!
    $automaticItems: [CartItemInput!]!
    $manualItems: [String!]!
    $email: String!
  ) {
    userInputRfq(
      deliveryAddress: $deliveryAddress
      deliveryDate: $deliveryDate
      phased: $phased
      status: $status
      cartName: $cartName
      automaticItems: $automaticItems
      manualItems: $manualItems
      email: $email
    )
  }
`;

const useSubmitRfq = () => {
  const { t } = useTranslation();

  const [mutate, { loading, data, error }] = useMutation<{ userInputRfq: Mutation["userInputRfq"] }, MutationUserInputRfqArgs>(MUTATION_SUBMIT_RFQ, {
    context: { public: true },
    notifyOnNetworkStatusChange: true,
  });

  const submitRfq = async (data: MutationUserInputRfqArgs) => {
    // check if all required fields are not null or undefined

    if (data.cartName === null || data.cartName === undefined) return;
    if (data.status === null || data.status === undefined) return;
    if (data.phased === null || data.phased === undefined) return;
    if (data.manualItems === null || data.manualItems === undefined) return;
    if (data.deliveryDate === null || data.deliveryDate === undefined) return;
    if (data.automaticItems === null || data.automaticItems === undefined) return;
    if (data.deliveryAddress === null || data.deliveryAddress === undefined) return;
    if (data.email === null || data.email === undefined) return;

    try {
      const res = await mutate({ variables: { ...data } });

      if (!res.data?.userInputRfq) throw new Error("Error submitting RFQ");

      showNotification({ color: "green", title: t("useSubmitRfqText1"), message: t("useSubmitRfqText2") });

      trackAction("submit_rfq", {
        name: data.cartName,
        status: data.status,
        phased: data.phased,
        manual_items: data.manualItems,
        delivery_date: data.deliveryDate,
        automatic_items: data.automaticItems,
        delivery_address: data.deliveryAddress,
      });
    } catch (error: any) {
      showNotification({ color: "red", title: t("useSubmitRfqText3"), message: t("useSubmitRfqText4") });
      throw error;
    }
  };

  return { loading, data, error, submitRfq };
};

export default useSubmitRfq;
