import { Button, Group, Indicator, Space, Text, TextInput, Title } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SlideTransition } from "../../components/Transitions";
import { IRequestMetaData } from "../../typing/declarations";
import usePlacesInput from "../hooks/usePlacesInput";
import useProjectInfoForm from "../hooks/useProjectInfoForm";

interface Props {
  loading?: boolean;
  onPrevious: () => void;
  onSubmit: (args: IRequestMetaData) => void;
  transitionDirection: "right" | "left";
}

const ProjectInfo: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const form = useProjectInfoForm(props.onSubmit);
  const places = usePlacesInput(form.setDeliveryAddressWithGooglePlaces);

  const renderDay = (date: Date) => {
    const today = new Date();
    const show = today.toDateString() !== date.toDateString();
    return (
      <Indicator size={10} c="green" offset={8} disabled={show}>
        <div>{date.getDate()}</div>
      </Indicator>
    );
  };

  const excludeWeekends = (date: Date) => {
    const dayOfWeek = date.getDay();
    return dayOfWeek !== 0 && dayOfWeek !== 6;
  };

  return (
    <SlideTransition direction={props.transitionDirection}>
      <Container>
        <Title>{t("requestProjectInfoText1")}</Title>
        <Text>{t("requestProjectInfoText2")}</Text>
        <form>
          <TextInput
            name="cartName"
            aria-label="Cart name"
            className="input-item"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            defaultValue={form.values.cartName}
            label={t("requestProjectInfoText3")}
            placeholder={t("requestProjectInfoText4")}
            error={form.touched.cartName && form.errors.cartName ? t("requestProjectInfoText5") : ""}
          />
          <DateInput
            firstDayOfWeek={0}
            name="deliveryDate"
            renderDay={renderDay}
            className="input-item"
            onBlur={form.handleBlur}
            aria-label="Delivery Date"
            //@ts-ignore
            value={form.values.deliveryDate}
            label={t("requestProjectInfoText8")}
            onChange={(date) => form.setFieldValue("deliveryDate", date)}
            minDate={dayjs(new Date()).startOf("day").add(2, "days").toDate()}
            error={form.touched.deliveryDate && form.errors.deliveryDate ? t("requestProjectInfoText9") : ""}
            excludeDate={excludeWeekends}
          />
          <TextInput
            name="deliveryAddress"
            className="input-item"
            ref={places.inputRef}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            data-testid="delivery-address"
            defaultValue={form.values.deliveryAddress}
            label={t("requestProjectInfoText10")}
            placeholder={t("requestProjectInfoText11")}
            error={form.touched.deliveryAddress && form.errors.deliveryAddress ? t("requestProjectInfoText12") : ""}
          />
          <TextInput
            name="email"
            aria-label="Email"
            className="input-item"
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            defaultValue={form.values.email}
            label={t("requestProjectInfoText15")}
            placeholder={t("requestProjectInfoText16")}
            error={form.touched.email && form.errors.email ? t("requestProjectInfoText17") : ""}
          />
          <Space h={"lg"} />
          <Group justify="center" mt="md">
            <Button variant="default" onClick={props.onPrevious} leftSection={<IconArrowLeft />}>
              {t("requestProjectInfoText13")}
            </Button>
            <Button
              loading={props.loading}
              disabled={!form.isValid}
              onClick={() => form.handleSubmit()}
              data-testid="project-info-submit-button"
              leftSection={<IconArrowRight />}
            >
              {t("requestProjectInfoText14")}
            </Button>
          </Group>
        </form>
      </Container>
    </SlideTransition>
  );
};

const Container = styled.div`
  .input-item {
    margin-top: 20px;
  }
  margin: auto;
  max-width: 928px;

  @media (max-width: 576px) {
    border-top: 1px solid #eee;
  }
`;

ProjectInfo.defaultProps = {
  transitionDirection: "right",
};

export default ProjectInfo;
