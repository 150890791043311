import { Estimate } from "../../typing/gql.schema";
import isEstimateDiscounted from "./isEstimateDiscounted";

const useShowTotalsBundlePricing = (estimate: Estimate, individualSubTotal: number) => {
  if (!isEstimateDiscounted(estimate)) return false;

  if (individualSubTotal === estimate?.subtotal) return false;

  return true;
};

export default useShowTotalsBundlePricing;
