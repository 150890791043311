import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import useAuth from "../../hooks/useAuth";
import useProjectCartItems from "../../hooks/useCartItems";
import { ICartItems, IRequestMetaData } from "../../typing/declarations";
import { CartItemInput, MutationUserInputRfqArgs } from "../../typing/gql.public.schema";

interface Props {
  submitRfq?: (args: MutationUserInputRfqArgs) => Promise<void>;
}

const getAutomaticItems = (cartItems: ICartItems) => {
  return cartItems.reduce((acc, current) => {
    if (!current.size?.id) return acc;
    if (!current.plant?.id) return acc;

    const item = {
      id: "0",
      notes: "",
      cartID: "0",
      ignore: false,
      sizeID: current.size.id,
      plantID: current.plant.id,
      quantity: current.quantity!,
      targetMargin: -1,
      priceOverride: -1,
      unavailable: false,
      availabilityOverride: -1,
    };

    return [...acc, item];
  }, [] as CartItemInput[]);
};

const getManualItems = (cartItems: ICartItems) => {
  return cartItems.reduce((acc, current) => {
    if (current.size?.id) return acc;
    if (current.plant?.id) return acc;

    let text = "";
    if (current.notes) {
      text = `${current.quantity} x '${current.size?.name}' ${current.plant?.latinName} [${current.notes}] (${current.plant?.commonName})`;
    } else {
      text = `${current.quantity} x '${current.size?.name}' ${current.plant?.latinName} (${current.plant?.commonName})`;
    }

    return [...acc, text];
  }, [] as Array<string>);
};

const useRequests = (props: Props) => {
  const auth = useAuth();
  const { cartItems, clearCartItems } = useProjectCartItems();
  const [metaData, setMetaData] = useState({} as IRequestMetaData);
  const [currentStep, setCurrentStep] = useLocalStorage<number>("request-step", 0);
  const [previousStep, setPreviousStep] = useLocalStorage<number>("request-previous-step", 0);

  const nextStep = () => {
    if (currentStep > 2) return;
    setPreviousStep(currentStep);
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep < 1) return;
    setPreviousStep(currentStep);
    setCurrentStep(currentStep - 1);
  };

  const submitRequest = async (data: Omit<MutationUserInputRfqArgs, "manualItems" | "automaticItems">) => {
    const manualItems = getManualItems(cartItems);
    const automaticItems = getAutomaticItems(cartItems);
    try {
      await props.submitRfq?.({ ...data, manualItems, automaticItems });

      clearCartItems();
      setCurrentStep(2);
      localStorage.removeItem("project-info-form");
      localStorage.removeItem("confirmation-form");
    } catch (error: any) {
      Sentry.captureException(error, { extra: { data }, tags: { component: "useRequests" } });
    }
  };

  const onFinishProjectInfo = async (metaData: IRequestMetaData) => {
    await submitRequest({ ...metaData, email: auth.activeUser?.email! });
  };

  const onCancelRequest = () => setMetaData({} as IRequestMetaData);

  const onBuyAgain = () => {
    setCurrentStep(0);
    setPreviousStep(0);
  };

  const transitionDirection = previousStep > currentStep ? "left" : ("right" as "left" | "right");

  return {
    ...auth,
    metaData,
    currentStep,
    previousStep,
    transitionDirection,
    nextStep,
    prevStep,
    onBuyAgain,
    setMetaData,
    onCancelRequest,
    onFinishProjectInfo,
  };
};

export default useRequests;
